















































import { Cuadrilla } from '@/entidades/Plantacion/Cuadrilla';
import { CuadrillaHorario, CuadrillaHorarioDetalle } from '@/entidades/Plantacion/CuadrillaHorario';
import Empleado from '@/entidades/Plantacion/Empleado';
import Alerta from '@/entidades/Sistema/Alerta';
import moment from 'moment';
import {Vue,Component} from 'vue-property-decorator';
import {State,Action,Mutation} from 'vuex-class';
@Component({
    name : 'HorarioAdd'
})
export default class HorarioAdd extends Vue
{
 
 //#region Vuex
    @State('cuadrillas',{namespace:'maestroForestal'}) cuadrillas!:Array<Cuadrilla>;
    @State('empleados',{namespace:'maestroForestal'}) empleados!:Array<Empleado>;
    @State('horarioEmpleados',{namespace:'laboresForestal'}) horarios!:Array<CuadrillaHorario>;
    @Action('changeAlerta',{namespace:'sistemaModule'}) changeAlerta!:Function;
    @Action('changeLoading',{namespace:'sistemaModule'}) changeLoading!:Function;
    @Mutation('setHorarioAdd',{namespace:'laboresForestal'}) agregarHorario!:Function;
 //#endregion
 //#region EventosLocales
    changeEmpleado(item:number)
    {
        this.listadoEmpleado = [];
        var empleado = this.empleados.filter(c=>c.cuadrillaId == item);
        if(empleado.length > 0 && empleado != undefined)
        {
            empleado.forEach(t=>{
                this.listadoEmpleado.push(new CuadrillaHorarioDetalle(t.empleadoId,t.fullName,true));
            });
        }
        else 
        {
            this.changeAlerta(new Alerta("No se encontro Empleados, para esta cuadrilla",true,"danger",3000));
        }
    }
    addHorario()
    {
        var hours = this.horarios.find(c=>c.cuadrillaId == this.cuadrillaId && c.fecha == this.fechaActual);
        if(hours != undefined || hours != null)
        {
            this.changeAlerta(new Alerta("Ya se encuentre registrada una asistencia,para esta cuadrilla",true,"danger",3000));
            return;
        }
        var asignacion = new CuadrillaHorario();
        asignacion.cuadrillaId = this.cuadrillaId;
        asignacion.cuadrillaName = this.cuadrillas.find(c=>c.cuadrillaId == this.cuadrillaId)?.descripcion;
        asignacion.fecha = this.fechaActual;
        asignacion.estadoProceso = "REGISTRO LOCAL";
        asignacion.registrado = false;
        var asistion:boolean =false;
        this.listadoEmpleado.forEach(e=>{
            var emplAsi = this.selected.find(c=>c.empleadoId == e.empleadoId);
            if(emplAsi != undefined)
                asistion= true;
            asignacion.detalle.push(new CuadrillaHorarioDetalle(e.empleadoId,e.empleadoName,asistion))
            asistion = false;
        })

        this.agregarHorario(asignacion);
        this.$emit('limpiar');
    }
 //#endregion

 //#region Variablas
    fechaActual:string="";
    cuadrillaId:number=0;
    listadoEmpleado:Array<CuadrillaHorarioDetalle> =[];
    header:{text:string,sortable:boolean,value:string}[] =
    [
        {text:"Empleado",sortable:false,value:'empleadoName'}
                                 
    ];
    selected:Array<CuadrillaHorarioDetalle>=[];
 //#endregion


    mounted() {
       this.fechaActual =  moment(new Date()).format("YYYY-MM-DD");  
    }
}
